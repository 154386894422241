.deposit {
    max-width: 452px;
    width: 100%;
    height: 100%;
    // min-height: 740px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: rgba(242, 243, 245, 1);
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;

    .deposit-header {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 72px;

        .go-back-portfolio {
            cursor: pointer;
            position: absolute;
            left: 24px;
        }
        p {
            font-size: 16px;
            font-weight: 600;
            color: #2b2f38;
        }
    }
    .deposit-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 120px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        .deposit-title {
            font-family: "SpaceGrotesk";
            font-size: 20px;
            font-weight: 600;
            line-height: 42px;
            color: rgba(43, 47, 56, 1);
        }
    }
    .inform-about-deposit-modal {
        animation: informModal 0.3s ease-in-out;

        .closeDiv {
            img {
                margin-bottom: 4px;
            }
        }
        label {
            font-size: 13px;
        }
        p {
            margin-bottom: 12px;
        }
        span {
            margin-bottom: 16px;
        }
    }

    @keyframes informModal {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
