.transaction-info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  .transaction-info-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 32px 8px 0px;
    border-bottom: 0.5px solid rgba(191, 196, 206, 1);

    p {
      max-width: 65px;
      margin: 0;
      padding: 0;
      font-size: 10px;
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
      color: rgba(78, 85, 102, 1);
    }
  }
}

.transaction-row {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 40px 12px 0;
  border-bottom: 0.5px solid rgba(191, 196, 206, 1);
  p {
    max-width: 65px;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: rgba(43, 47, 56, 1);
  }
  img {
    cursor: pointer;
    position: absolute;
    right: 0;
  }
}
