.personal-info-card {
    width: 100%;
    height: 56px;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid rgba(191, 196, 206, 1);
    gap: 10px;

    p {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        color: rgba(43, 47, 56, 1);
    }
    .warning-icon {
        position: relative;

        &:after {
            content: "";
            background-image: url("../../../../assets/WarningAcc.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 14px;
            position: absolute;
            right: -14px;
            top: -4px;
            width: 16px;
            height: 14px;
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
    div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        max-width: 60%;

        span {
            font-size: 16px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
            text-align: start;
            overflow: hidden;
        }
        img {
            cursor: pointer;
        }
    }
}
.security-card {
    cursor: pointer;
}
@media (max-width: 348px) {
    .personal-info-card-media {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding-block: 8px;

        div {
            max-width: 100%;
            width: 100%;
            justify-content: space-between;
            @media (max-width: 300px) {
                span {
                    text-align: left;
                    width: 70%;
                    overflow: scroll;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
    }
}
