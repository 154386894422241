@font-face {
	font-family: "NotoSans";
	src: url("../assets/fonts/NotoSans-Regular.ttf");
	font-display: swap;
}
@font-face {
	font-family: "SpaceGrotesk";
	src: url("../assets/fonts/SpaceGrotesk-Regular.ttf");
	font-display: swap;
}
@font-face {
	font-family: "ArTar";
	src: url("../assets/fonts/ArTar.ttf");
	font-display: swap;
}

@mixin flex-center {
	display: flex;
	align-items: center;
	justify-content: center;

	// @import "../../../styles/main.scss";
	// .class {
	// 	@include flex-center;
	//  . . .
	// }
}

* {
	font-family: "NotoSans";
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	border: 0;
	user-select: none;
}
body {
	overflow-x: hidden;
}
img {
	// pointer-events: none;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}
html.noscroll {
	position: fixed;
	overflow-y: scroll;
	width: 100%;
}
.disable-scroll {
	overflow: hidden !important;
}
.app {
	overflow: hidden;
}
input::placeholder {
	font-family: "NotoSans";
	font-size: 16px !important;
	letter-spacing: 0;
	font-weight: 400;
	color: rgba(122, 132, 153, 1);
}
@media (max-width: 750px) {
	.backgroundElement {
		display: none !important;
	}
}

.legalEntityCheckbox {
	margin: 20px 0 20px 5px;
}
.legalEntityCheckboxFP {
	margin-top: 35px;
	margin-left: 0;
}
.btn-wrapper {
	position: absolute;
	bottom: 40px;
	width: 100%;
	padding-right: 24px;
	padding-left: 24px;
	button {
		width: 100% !important;
	}

	//                      <fixed/>
	// padding-left: 24px;
	// padding-right: 24px;
	// width: 100%;
	// position: fixed;
	// display: flex !important;
	// align-items: center;
	// justify-content: space-between;
	// max-width: 440px;
	// bottom: 40px;
	// z-index: 10;
	//                     </fixed/>
}

// <toastify>
.session-expired {
	background: rgba(242, 243, 245, 1) !important;

	svg {
		fill: rgb(50, 50, 50) !important;
	}
}
.session-expired-text {
	font-size: 16px;
	font-weight: 600;
	color: rgb(50, 50, 50);
}

.notification-modal {
	width: 100% !important;
	margin: 0 auto !important;
	max-width: 420px !important;
	height: 48px !important;
	min-height: 48px !important;
	max-height: 48px !important;
	background: rgba(0, 9, 28, 1) !important;
	border-radius: 6px !important;
	display: flex;
	align-items: center;
	bottom: 85px;
}
.notification-modal-text {
	margin: 0;
	padding: 0;
	padding-left: 16px;
	font-size: 16px;
	font-weight: 400;
	color: rgba(255, 255, 255, 1);
}
// </toastify>

.assets-loading {
	color: rgba(248, 0, 158, 1);
	font-weight: 600;
	font-size: 18px;
	padding-left: 10px;
}
.assets-loading-page {
	position: absolute;
	top: 40%;
}
.not-found-coming-soon {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: 55px;
	animation: notFound 0.3s;

	p {
		font-weight: 600;
		font-size: 36px;
		filter: saturate(0);
		background: -webkit-linear-gradient(45deg, rgba(248, 211, 235, 1), rgba(187, 207, 250, 1));
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		transition: 0.2s;
		animation: notFoundtext 5s infinite;

		&:hover {
			filter: saturate(1);
			animation: none;
		}
	}
}
@keyframes notFoundtext {
	0% {
		filter: saturate(0);
	}
	15% {
		filter: saturate(0);
	}
	50% {
		filter: saturate(1);
	}
	85% {
		filter: saturate(0);
	}
	100% {
		filter: saturate(0);
	}
}
@keyframes notFound {
	from {
		margin-top: 0;
		font-size: 28px;
	}
	to {
		margin-top: 55px;
		font-size: 36px;
	}
}

.layout-acc-verification-status {
	font-family: "SpaceGrotesk";
	text-align: center;
	font-size: 14px;
	font-weight: 600;
	color: rgb(43, 47, 56);
	background-color: rgba(255, 0, 0, 0.6);
	backdrop-filter: blur(10px);
	max-width: 450px;
	padding: 0 32px;
	position: fixed;
	top: 100px;
	z-index: 110;
}
// <BottomSheetModal styles>
.bottom-sheet-modal-wrapper {
	width: 450px;
	height: 100vh;
	display: flex;
	align-items: center;
	position: fixed;
	bottom: 0;
	z-index: 110 !important;
	overflow: hidden;
}
// </BottomSheetModal styles>

// <Volatility styles>
.changed-price-upswing {
	animation: PriceUpswing 1s;
}
@keyframes PriceUpswing {
	0%,
	20% {
		opacity: 0.8;
		color: #27ae60;
	}
	100% {
		opacity: 1;
		color: #2b2f38;
	}
}
.changed-price-downswing {
	animation: PriceDownswing 1s;
}
@keyframes PriceDownswing {
	0%,
	20% {
		opacity: 0.8;
		color: #eb5757;
	}
	100% {
		opacity: 1;
		color: #2b2f38;
	}
}
// </Volatility styles>

.grecaptcha-badge {
	visibility: hidden !important;
	display: none !important;
}

//   ..............................

//todo  / HIDE SCROLLBAR /
// -ms-overflow-style: none;
// scrollbar-width: none;
// &::-webkit-scrollbar {
// 	display: none;
// }

//   ..............................

//todo  / SIRCAP CUSTOMM BOX-SHADOW /
// box-shadow:
//     rgba(240, 46, 170, 0.4) 0px 5px,
//     rgba(240, 46, 170, 0.3) 0px 10px,
//     rgba(240, 46, 170, 0.2) 0px 15px,
//     rgba(240, 46, 170, 0.1) 0px 20px,
//     rgba(240, 46, 170, 0.05) 0px 25px;

//   ..............................
