.strategy-page {
    max-width: 452px;
    width: 100%;
    height: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f9;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 20;
    overflow: hidden;

    .sm-info-container {
        padding: 0px !important;
    }

    .strategy-header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        min-height: 180px;
        height: 180px;
        background: linear-gradient(76.95deg, #099773 -1.99%, #43b692 110.64%);
        border-radius: 0px 0px 16px 16px;

        &.strategy-header-container-1 {
            background: linear-gradient(76.95deg, #099773 -1.99%, #43b692 110.64%);
        }
        &.strategy-header-container-2 {
            background: linear-gradient(256.2deg, #6eee87 -7.74%, #5fc52e 100%);
        }
        &.strategy-header-container-3 {
            background: linear-gradient(77deg, #ff0f7b -0.99%, #f89b29 106.54%);
        }

        .strategy-header {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 72px;
            min-height: 72px;

            .go-back-strategy {
                cursor: pointer;
                position: absolute;
                left: 24px;
                filter: brightness(0) invert(0.95);
            }

            p {
                font-size: 16px;
                font-weight: 600;
                color: #f9f9f9;
                letter-spacing: 0em;
                text-align: center;
            }
        }

        .strategy-header-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 8px;
            padding: 16px 24px;

            .sh-info-box {
                max-width: 175px;
                min-width: 115px;
                height: 76px;
                padding: 8px;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                background: #00000026;
                text-align: left;

                p {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    letter-spacing: 0em;
                    color: #f9f9f9;
                }
                span {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0.005em;
                    text-align: left;
                    color: #f9f9f9;
                }

                &:nth-last-child(1) {
                    min-width: 80px;
                    max-width: 115px;
                    height: 76px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }

                @media (max-width: 375px) {
                    min-width: 0;
                    height: 87px;
                    &:nth-last-child(1) {
                        min-width: 70px;
                        height: 87px;
                    }
                }
            }
        }
    }

    .tab-bar-cat {
        display: flex;
        gap: 16px;
        padding: 20px 0 0 24px;
        overflow: scroll;
        width: 100%;
        // padding: 20px 24px;
        position: sticky;
        min-height: 77px;
        top: 0px;
        z-index: 10;
        background: #f9f9f9;

        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .strategy-main {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex-grow: 1;
        position: relative;
        padding: 24px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        .strategy-info-section {
            width: 100%;
            background-color: white;
            border-radius: 16px;
            position: relative;
            button {
                width: 50%;
                height: 44px;
                min-height: 44px;
                border-radius: 16px;
                background-color: inherit;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                color: #2b2f38;
                transition: 0s;
            }
            .active-btn {
                color: #f9f9f9;
                transition:
                    all 0.7s,
                    color 0.3s;
                background: #000f30;
            }
        }

        .sm-info-container {
            width: 100%;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            -ms-overflow-style: none;
            scrollbar-width: none;
            padding-block: 32px 95px !important;

            &::-webkit-scrollbar {
                display: none;
            }
        }
        .sm-info-container-description {
            display: flex;
            h4,
            p,
            li {
                text-align: left;
            }
            ul {
                display: flex;
                flex-direction: column;
            }
            li {
                padding-left: 25px;
                list-style-type: none !important;
                position: relative;

                &::before {
                    content: "";
                    background-image: url("../../../assets/ListStyleCheck.svg");
                    background-position: center;
                    background-size: 100%;
                    width: 20px;
                    height: 21px;
                    position: absolute;
                    left: 0;
                }
            }
        }

        // custom
        .sm-tab-name {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-bottom: 85px;

            .asset-logo {
                max-width: 40px;
                width: 40px;
                object-fit: contain;
                filter: brightness(0.9);
            }

            .stock-info-bargaining {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-block: 16px;

                .stock-source-status {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;
                }

                .stock-info {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 16px;
                    text-align: left;
                    width: 100%;

                    .stock-info-header {
                        display: flex;
                        flex-direction: row;
                        gap: 6px;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        .stock-price-status {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            flex-direction: row;
                            gap: 8px;
                        }
                    }

                    div {
                        display: flex;
                        flex-direction: column;
                        row-gap: 4px;
                    }
                    img {
                        width: 40px;
                        height: 40px;
                    }
                    .price {
                        font-size: 24px;
                        font-weight: 600;
                        color: rgba(0, 15, 48, 1);
                    }
                    .gain-percent {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        span {
                            font-size: 14px;
                            font-weight: 600;
                            color: rgba(39, 174, 96, 1);
                        }
                        .gains-percent {
                            border-left: 1px solid rgba(191, 196, 206, 1);
                            padding-left: 8px;
                            margin-left: 8px;
                            height: 12px;
                            line-height: 12px;
                        }
                        .loss {
                            color: rgba(235, 87, 87, 1);
                        }
                    }
                    p {
                        font-size: 11px;
                        font-weight: 500;
                        color: rgba(78, 85, 102, 1);
                    }
                }
            }

            // ..
            .dividents-title {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                    font-size: 12px;
                    font-weight: 500;
                    text-align: center;
                    color: rgba(78, 85, 102, 1);
                }
            }

            // ..
            .indicators-title {
                margin-block: 0px 8px !important;

                p {
                    font-size: 18px !important;
                }
                span {
                    font-size: 14px !important;
                }

                .indicators-list-label {
                    font-size: 14px !important;
                    margin-block: 6px;
                    font-weight: 400 !important;
                    font-family: "NotoSans";
                }
                ul li {
                    list-style-position: inside;
                    font-size: 13px;
                    padding-left: 4px;
                    word-break: break-all;
                }
            }
            .indicators-info {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: start;
                gap: 2px;
                text-align: left;
                margin-bottom: 4px;

                p {
                    font-weight: 600;
                    font-size: 16px;
                }
                span {
                    font-size: 14px;
                }

                ul li {
                    list-style-position: inside;
                    font-size: 13px;
                    padding-left: 4px;
                    word-break: break-all;
                }
            }
            .swiper {
                margin-top: 20px;
                padding: 0 !important;
            }
            .strategy-indicator-box {
                // padding: 0;
                z-index: 0;
            }
            .indicator-box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                min-width: 85% !important;
                border-radius: 6px;
                border: 1.5px solid #e0e3eb;
                padding: 13px;
                gap: 6px;
                height: 100%;

                p {
                    color: #131722;
                    display: flex;
                    flex-wrap: wrap;
                    font-weight: 600;
                    font-size: 13px;
                }

                .indicator-box-row {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    width: 100%;

                    span {
                        font-size: 14px;
                    }
                }
            }

            .review-title {
                width: 100%;
                text-align: left;
                margin-block: 16px;

                p {
                    margin-bottom: 16px;
                    font-family: "SpaceGrotesk";
                    font-size: 20px;
                    font-weight: 620;
                    color: rgba(43, 47, 56, 1);
                }
                .review-list-label {
                    font-size: 16px;
                    margin-block: 6px;
                    font-weight: 600;
                    font-family: "NotoSans";
                }
                .review-list-info {
                    font-size: 14px;
                    color: #2b2f38;
                    margin-bottom: 10px;
                }
                ul li {
                    list-style-position: inside;
                    font-size: 13px;
                    padding-left: 4px;
                    word-break: break-all;
                }
                .review-title-content {
                    p {
                        margin-bottom: 4px;
                        font-family: "SpaceGrotesk";
                        font-size: 16px;
                        font-weight: 620;
                        color: rgba(43, 47, 56, 1);
                        margin-top: 12px;
                    }
                    span {
                        font-size: 13px;
                        white-space: break-spaces;
                    }
                    img {
                        width: 100%;
                        margin-block: 16px;
                    }
                }
            }
            .description-span {
                margin-bottom: 16px;
                width: 100%;
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                color: rgba(43, 47, 56, 1);
                white-space: break-spaces;
            }
            .description-details {
                padding-bottom: 16px;
                width: 100%;
            }
            .info-details {
                width: 100%;
            }
        }

        .sm-tab-trading {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            min-height: 100%;

            .current-investment-amount-strategy {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                // padding: 0 24px 16px 24px;
                padding: 24px;
                margin-bottom: 24px;

                label {
                    text-align: center;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgb(124, 125, 129);
                }

                p {
                    width: 100%;
                    margin-top: 10px;
                    text-align: center;
                    word-break: break-all;
                    font-size: 40px;
                    line-height: 40px !important;
                    font-weight: 600;
                    color: rgb(43, 47, 56);
                }
            }

            .brokerage-section-name {
                font-family: "SpaceGrotesk";
                font-size: 20px;
                font-weight: 620;
                line-height: 26px;
                letter-spacing: 0em;
                text-align: left;
                width: 100%;
                color: rgba(43, 47, 56, 1);
            }

            .report-date-item-wrapper {
                width: 100%;
                max-height: 285px !important;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                gap: 1px;
                margin-top: 16px;
                overflow-y: scroll;
                border-radius: 6px;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }

                .report-item-container {
                    background-color: rgba(255, 255, 255, 0.8);
                }
            }
        }
    }
    .strategy-action-btns-wrapper {
        width: 100%;
        padding: 0 24px;
        position: fixed;
        display: flex !important;
        align-items: center;
        justify-content: space-around;
        max-width: 450px;
        bottom: 40px;
        z-index: 10;
        gap: 12px;

        .action-btn {
            flex-grow: 1;
            max-width: none;
            height: 45px !important;
            min-height: 45px !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
        }
    }
    .btn-wrapper {
        z-index: 99;
    }
}
