@import "../../../../styles/main.scss";

.activities-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 2px;
    gap: 10px;
    border-bottom: 0.5px solid rgba(191, 196, 206, 1);

    &:nth-last-child(1) {
        border-bottom: none;
    }

    .price-and-activity {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 4px;

        p {
            font-size: 16px;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
        }
        span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(78, 85, 102, 1);
        }
    }
    .status {
        display: flex;
        align-items: end;
        justify-content: center;
        flex-direction: column;
        gap: 8px;

        .status-field {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
        p {
            font-size: 12px;
            font-weight: 400;
            text-align: right;
        }
        .Cancellation,
        .Pending {
            color: rgba(255, 165, 0, 1);
        }
        .Successful {
            color: rgba(39, 174, 96, 1);
        }
        .Rejected,
        .Error,
        .Cancelled {
            color: rgba(235, 87, 87, 1);
        }
    }
}
.transactions-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 2px;
    gap: 10px;
    border-bottom: 0.5px solid rgba(191, 196, 206, 1);

    &:nth-last-child(1) {
        border-bottom: none;
    }

    .transaction-asset-info {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        .name-type {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;

            p {
                font-size: 15px;
                font-weight: 400;
                line-height: 22px;
                color: rgba(43, 47, 56, 1);
            }
            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: rgba(78, 85, 102, 1);
            }
        }
        img {
            width: 40px;
            height: 40px;
        }
    }
    .price-percent-status {
        display: flex;
        gap: 12px;
        align-items: flex-start;

        div {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: flex-end;
            text-align: right;

            p {
                font-size: 16px;
                font-weight: 600;
                color: rgba(43, 47, 56, 1);
            }
            .price-pending {
                color: rgba(255, 165, 0, 1);
            }
            .price-rejected {
                color: rgba(235, 87, 87, 1);
            }
            span {
                font-size: 14px;
                font-weight: 400;
                color: rgba(78, 85, 102, 1);
            }
        }
    }
}
.cancel-pending-deal {
    @include flex-center;
    position: relative;
    gap: 6px;
    background: inherit;
    color: rgba(235, 87, 87, 1);
    margin-right: 1px;
    padding-left: 2px;
    font-size: 12px;
    font-weight: 600;

    span {
        color: rgba(235, 87, 87, 1) !important;
        font-size: 12px !important;
        font-weight: 600 !important;
    }

    img {
        width: 18px;
        border-radius: 100%;
    }

    &::after {
        content: "";
        position: absolute;
        width: 0px;
        height: 1.5px;
        left: 50%;
        bottom: -2px;
        background-color: rgba(235, 87, 87, 1) !important;
        transition: all ease-in-out 0.2s;
    }
    &:hover::after {
        width: 100%;
        left: 0;
    }
    &:active {
        color: red !important;

        span {
            color: red !important;
        }

        img {
            filter: invert(18%) sepia(100%) saturate(7494%) hue-rotate(1deg) brightness(99%) contrast(80%);
        }

        &::after {
            background-color: red !important;
        }
    }
}
