.createPassword {
    width: 555px;
    height: 632px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 10px 40px 10px rgba(51, 51, 79, 0.01);
    z-index: 10;
    position: relative;

    p {
        font-family: "SpaceGrotesk";
        font-size: 28px;
        font-weight: 600;
        color: rgba(43, 47, 56, 1);
        text-align: center;
    }
    span {
        margin: 10px 0 56px 0;
        font-size: 16px;
        font-weight: 400;
        color: rgba(78, 85, 102, 1);
        text-align: center;
        max-width: 80%;
    }
    .costumRow {
        margin-bottom: 16px;
        .error {
            display: none;
        }
    }
    .form-row {
        position: relative;
        .error {
            display: none;
        }
    }
    .costumBtn {
        margin-top: 56px;
    }
}
@media (max-width: 750px) {
    .createPassword {
        width: 100%;
        max-width: 455px;
        padding-top: 0;
        background: inherit;
        box-shadow: none;
        
        span{
            width: 100% !important;
            max-width: 100%;
        }
        .validationCheckbox {
            p {
                text-align: left;
                width: auto;
            }
        }
        form {
            width: 100%;
            .form-row{
                margin-bottom: 16px;
            }
            .input-container{
                width: 100%;
                input {
                    width: 100%;
                }
            }
         
            button {
                width: 100%;
            }
        }
    }
}
@media (max-width: 500px) {
    .createPassword {
        button {
            width: 100%;
            margin-top: 24px !important;
        }
    }
}
