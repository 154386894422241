.filter-bottom-sheet-modal-wrapper {
    width: 450px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    z-index: 110 !important;
    overflow: hidden;
}
.filter-modal {
    width: 100%;
    max-width: 450px;
    overflow-x: hidden;
    max-height: 60vh;
    height: auto;
    padding: 62px 0px 40px 0px;
    border-radius: 18px 18px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(249, 249, 249, 1);
    position: fixed;
    bottom: 0;
    z-index: 110 !important;
    animation: filterModal 0.5s ease-out;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    .filter-modal-header {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: space-between;
        padding: 22px 24px 8px 24px;
        height: 58px;
        min-height: 58px;
        border-bottom: 0.5px solid rgba(230, 231, 235, 1);
        background-color: rgba(249, 249, 249, 1) !important;
        position: sticky;
        top: -62px;
        margin-top: -62px;
        z-index: 2;

        @media (max-width: 450px) {
            margin-left: 0px;
        }
        span {
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0.0075em;
            color: #7a8499;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                color: #000f30;
            }
        }
        p {
            font-size: 1rem;
            font-weight: 600;
            color: rgba(43, 47, 56, 1);
            max-width: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 18px;
            max-width: 250px;
        }
    }

    .edit-profile-row {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 24px;
        justify-content: flex-start;
        min-height: 56px;
        height: 56px;
        border-bottom: 0.5px solid rgba(230, 231, 235, 1);
        gap: 12px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(43, 47, 56, 1);
    }
}
@keyframes filterModal {
    from {
        max-height: 0;
    }
    to {
        max-height: 60vh;
    }
}
.outlet-filter-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 32, 32, 0.7);
    width: 100%;
    height: 110vh !important;
    top: -75px;
    left: 0;
    z-index: 100;
    position: absolute;
}

.filter-row {
    width: 100%;
    height: 56px;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    border-bottom: 0.5px solid rgba(230, 231, 235, 1);
    cursor: pointer;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        p {
            font-size: 16px;
            font-weight: 400;
            color: #2b2f38;
        }
    }
    p {
        font-size: 16px;
        font-weight: 400;
        color: #2b2f38;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}
.filter-date-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 8px 0px 8px;
    gap: 8px;

    .report-date-picker-container {
        background-color: inherit;
        span {
            color: rgba(78, 85, 102, 1);
        }
    }
}
.filter-r-rating-row {
    position: relative;
    width: 100%;
    height: 56px;

    .reliability-rating-row {
        height: 56px;
        min-height: 56px;
    }
    .check-icon {
        position: absolute;
        right: 24px;
        top: 16px;
    }
}
.filter-r-rating-modal {
    padding-bottom: 0px !important;
}
.filter-country-dropdown {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 0px;

    .filter-country-select {
        width: 100%;
        height: 100% !important;
    }

    .filter-country-selected {
        position: relative;
        border: 0px;
        border-radius: 0;
        border-bottom: 1px solid rgba(230, 231, 235, 1);

        &:after {
            content: "";
            width: 24px;
            height: 24px;
            background-image: url("../../../../assets/icon-check.svg");
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            right: 24px;
        }
    }

    .filter-country-dropdown-wrapper {
        background: rgb(249, 249, 249) !important;
        margin: 0;
        box-shadow: none;
        border-radius: 0;
        border: none;
        svg {
            display: none;
        }
        .styles_dropdown_item__4JE5x {
            border-bottom: 0.5px solid rgba(230, 231, 235, 1);
        }
    }
}

.filter-range-slider {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 24px 0 24px;
    gap: 15px;
    row-gap: 24px;
    margin-block: 24px;
}
.range-slider {
    background: #fdbfe7 !important;
    width: 100% !important;

    input[type="range"] {
        display: none;
    }

    .range-slider__range {
        background: #f8009e !important;
    }
    .range-slider__range[data-active] {
        /* CSS for active (actively being dragged) range */
    }
    .range-slider__thumb {
        background: #f8009e !important;
        border: 4px solid #f9f9f9;
    }
    .range-slider__thumb[data-active] {
        filter: brightness(0.92);
    }
}
